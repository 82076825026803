import { Component, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

declare var $: any;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  a: number;
  b: number;
  myForm: FormGroup;
  isValid = false;
  constructor(private modalService: NgbModal, private fb: FormBuilder) {



  }

  ngOnInit() {

    this.myForm = this.fb.group({
      result: ''
    });

    this.onChanges();

    this.a = this.getRandomInt(1, 10);
    this.b = this.getRandomInt(1, 10);

  }

  onChanges(): void {
    const total = this.a + this.b;
    this.myForm.valueChanges.subscribe(val => {
      if (val === total) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    });
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
