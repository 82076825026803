import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root',
  })
export class PlanService {

    base_url: string;
    base_api = environment.apiUrl;

    constructor(private http: HttpClient) {

    }

    getPlans<T>(): Observable<any> {
        return this.http.get<any>(this.base_api + '/plan' + `/web`).pipe<T>(map(resp => resp));
      }
}

