// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // apiUrl: 'http://clinifyapi-prod.sa-east-1.elasticbeanstalk.com/api/',
  // logiUrl: 'http://clinifyapi-prod.sa-east-1.elasticbeanstalk.com/api/login/authenticate',

  apiUrl: 'http://localhost:51970/api',
  logiUrl: 'http://localhost:51970/api/login/authenticate',

  // apiUrl: 'https://clinifyapi.azurewebsites.net/api',
  // logiUrl: 'https://clinifyapi.azurewebsites.net/api/login/authenticate',

  blobProfile: 'https://clinify.blob.core.windows.net/profile/',
  blobCompany: 'https://clinify.blob.core.windows.net/company/',
  blobCustomer: 'https://clinify.blob.core.windows.net/customer/',
  emptyUserImage: '/assets/img/avatars/profile-placeholder.png',
  emptyCompanyImage: './assets/img/icons/icone.svg',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
