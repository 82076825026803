import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  featuresSelected: any = "Atendimento";
  constructor() { }

  ngOnInit() {
  }


  features(recurso:any){
    debugger
      this.featuresSelected = recurso;
  }
}
