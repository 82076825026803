import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PlansComponent } from './plans/plans.component';
import { FeaturesComponent } from './features/features.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { TermsUseComponent } from './terms-use/terms-use.component';
import { SupportComponent } from './support/support.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent  },
  { path: 'planos', component: PlansComponent, pathMatch: 'full' },
  { path: 'recursos', component: FeaturesComponent , pathMatch: 'full'},
  { path: 'contato', component: ContactComponent , pathMatch: 'full'},
  { path: 'faq', component: FaqComponent , pathMatch: 'full'},
  { path: 'termos-e-condicoes', component: TermsUseComponent , pathMatch: 'full'},
  { path: 'suporte', component: SupportComponent , pathMatch: 'full'},

  
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
