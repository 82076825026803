import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    function navbarFixed() {
      if ($('.header_area').length) {
          $(window).scroll(function () {
              var scroll = $(window).scrollTop();
              if (scroll) {
                  $(".header_area").addClass("navbar_fixed");
              } else {
                  $(".header_area").removeClass("navbar_fixed");
              }
          });
      };
  };
  navbarFixed();


  function offcanvasActivator() {
      if ($('.bar_menu').length) {
          $('.bar_menu').on('click', function () {
              $('#menu').toggleClass('show-menu')
          });
          $('.close_icon').on('click', function () {
              $('#menu').removeClass('show-menu')
          })
      }
  }
  offcanvasActivator();

  $('.offcanfas_menu .dropdown').on('show.bs.dropdown', function (e) {
      $(this).find('.dropdown-menu').first().stop(true, true).slideDown(400);
  });
  $('.offcanfas_menu .dropdown').on('hide.bs.dropdown', function (e) {
      $(this).find('.dropdown-menu').first().stop(true, true).slideUp(500);
  });
  }

}
